@import '../scss/variables';
@import '../scss/mixins';

.meet-dr-waite-section {
  @include container-setup;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #F8F6F7;
  
  

  .video-container {
    flex: 1;
    height: 20rem;;

    iframe {
      width: 100%;
      height: 100%;
      max-width: 560px;
      max-height: 315px;
    }
  }

  .dr-info {
    text-align: center;
    flex: 1;
    padding-left: 20px;

    h2 {
      font-size: 28px;
      margin-bottom: 10px;
    }

    .certified-dermatologist {
      font-size: 18px;
      color: #A3863B; 
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .learn-more-btn {
      background-color: #333;
      color: #fff;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #555;
      }
    }
  }
}
