@import '../scss/variables';
@import '../scss/mixins';

.hero {
  position: relative;
  overflow: hidden;
  
  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the whole area like a background */
    z-index: -1; /* Keeps the video behind the text content */
  }

  .hero-container {
    @include container-setup;
    padding: 0;
    height: 50rem;
    display: flex;
    flex-direction: row;
    background: rgba($color: #ffffff, $alpha: 0.2);


    .hero-text {
      padding: 0 2rem;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      z-index: 1; /* Ensures the text is above the video */

      h2 {
        color: $blackColor;
        font-size: 3.3rem;
        font-family: $fontTitle;
        font-weight: 500;
      }

      p {
        margin-bottom: 3rem;
        color: $blackColor;
      }

      .btn {
        color: $whiteColor;
        padding: 1rem;
      }
    }
  }
}
