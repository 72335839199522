@import '../scss/variables';
@import '../scss/mixins';

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.best-facials {
  background: #E6DED2;
  margin: 0 auto;
  text-align: center;

  h2 {
    font-family: $fontTitle;
    font-size: 3rem;
    margin: 0;
    padding: 2rem;
  }

  .best-facials-container {
    @include container-setup;
    padding-bottom: 2rem;

    .best-facials-card {
      padding: 1rem;
      height: 35rem;
      border-right: 1px solid #333;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: transform 0.3s ease;
      
      &:hover {
        transform: scale(1.05);
      }

      .image-wrapper {
        width: 100%;
        height: 12rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h3 {
        font-size: 1rem;
        font-family: $fontTitle;
        font-weight: 400;
        color: $blackColor;
      }

      hr {
        color: $blackColor;
        width: 2rem;
        height: 1px;
        margin: 0 auto;
      }

      p {
        color: $blackColor;
        font-size: 0.9rem;
        line-height: 1.5;
      }

      .price {
        font-weight: bold;
      }
    }

    .btn {
      background: $blackColor;
      color: $whiteColor;
      padding: 0.75rem 1.5rem;
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 2rem;
    }
  }
}
