.promo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .promo-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
    h2 {
      font-size: 1.8rem;
      margin-bottom: 15px;
    }
    p {
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 20px;
    }
    .promo-link {
      display: inline-block;
      margin-top: 10px;
      background-color: #313F3C;
      color: white;
      padding: 10px 20px;
      text-decoration: none;
      border-radius: 5px;
      font-weight: bold;
      &:hover {
        background-color: #e35d52;
      }
    }
  }
}